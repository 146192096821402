import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import dayjs from 'dayjs';
import { add } from 'mathjs';
import axios from '../../utils/axios';
import { calculateTotalCheckout } from '../../utils/calculateTotalCheckout';
import { createDynamicURL } from '../../utils/dynamicParams';
import { fFormatDate } from '../../utils/formatTime';
import { RemoveNullValues } from '../../utils/removeNullableValues';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    rejectedStatus: 'idle',
    paymentStatus: 'idle',
    pdf: null,
    pdfStatus: 'idle',
    getPickupsStatus: 'idle',
    getReservationsStatus: 'idle',
    getDepositAmountStatus: 'idle',
    todayReservationsStatus: 'idle',
    todayReturnsStatus: 'idle',
    todayPickupsStatus: 'idle',
    reservationPaymentStatus: 'idle',
    reservations: [],
    invoiceReservation: [],
    pickups: [],
    returns: [],
    todayReservations: [],
    todayReturns: [],
    todayPickups: [],
    reservation: null,
    reservationItems: [],
    rejectedReservationItems: [],
    calendarReservations: [],
    reservationItem: null,
    reservationRentable: null,
    reservationExtras: [],
    totalRecords: 0,
    todayReservationsMeta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    todayReturnsMeta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    todayPickupsMeta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    ItemMeta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    stepOneData: {
        pickupLocationId: 0,
        returnLocationId: 0,
        startDate: new Date(),
        endDate: new Date(),
    },
    datesFromCalendar: {
        EndTime: new Date(),
        IsAllDay: false,
        ProjectId: 0,
        StartTime: new Date(),
        TaskId: 0,
        groupIndex: 0,
    },
    activeStep: 0,
    getFilterBy: '',
    isOpenFirstConductor: false,
    isOpenSecondConductor: false,
    isOpenConductor: false,
    parentChecked: true,
    childChecked: [
        { value: false, label: 'todayPickup' },
        { value: false, label: 'tomorrowPickup' },
        { value: false, label: 'todayReturn' },
        { value: false, label: 'tomorrowReturn' },
        { value: false, label: 'pending' },
        { value: false, label: 'inProgress' },
        { value: false, label: 'completed' },
        { value: false, label: 'canceled' },
    ],
    checkout: {
        extras: [],
        totalExtraPrice: 0,
        total: 0,
        discount: 0,
        totalPrice: 0,
        subTotal: 0,
        overload: 0,
        manualPrice: 0,
    },
    listReservationPaymentMethods: [],
    latestRentable: [],
    reservationPickup: {},
    reservationPayment: [],
    checkedOptions: [],
    selectedTypes: [],
    oneReservation: null,
    filteredRentableExtra: [],
    costTransfer: 0,
    depositAmount: 0,
    reservationPaymentDifference: 0,
    reservationPaymentPaid: 0,
    reservationTotalAmount: 0,
    isDescriptionRequired: true,
};
export const getReservationItems = createAsyncThunk('reservations/getReservationItems', async ({ page = 0, rowsPerPage = 5, orderBy = 'createdAt', order = 'desc', getFilterBy, rentableExtraId = '', customerId = '', childChecked, reservationStatus, rentableId, isPaging = true, filterName = '', }) => {
    let data;
    try {
        let addedUrl = '';
        const today = dayjs();
        const startDate = today.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = today.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        const tomorrow = dayjs().add(1, 'day');
        const startOfTomorrow = tomorrow.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfTomorrow = tomorrow.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        const todayPickupChecked = childChecked?.find((item) => item.label === 'todayPickup' && item.value);
        const tomorrowPickupChecked = childChecked?.find((item) => item.label === 'tomorrowPickup' && item.value);
        if (todayPickupChecked && !tomorrowPickupChecked) {
            addedUrl += `;reservation.startDate:${startDate},${endDate}`;
        }
        else if (!todayPickupChecked && tomorrowPickupChecked) {
            addedUrl += `;reservation.startDate:${startOfTomorrow},${endOfTomorrow}`;
        }
        else if (todayPickupChecked && tomorrowPickupChecked) {
            addedUrl += `;reservation.startDate:${startDate},${endOfTomorrow}`;
        }
        const todayReturnChecked = childChecked?.find((item) => item.label === 'todayReturn' && item.value);
        const tomorrowReturnChecked = childChecked?.find((item) => item.label === 'tomorrowReturn' && item.value);
        if (todayReturnChecked && !tomorrowReturnChecked) {
            addedUrl += `;reservation.endDate:${startDate},${endDate}`;
        }
        else if (!todayReturnChecked && tomorrowReturnChecked) {
            addedUrl += `;reservation.endDate:${startOfTomorrow},${endOfTomorrow}`;
        }
        else if (todayReturnChecked && tomorrowReturnChecked) {
            addedUrl += `;reservation.endDate:${startDate},${endOfTomorrow}`;
        }
        const statusLabels = ['pending', 'inProgress', 'completed', 'canceled'];
        const selectedStatuses = childChecked
            ?.filter((item) => statusLabels.includes(item.label) && item.value)
            .map((item) => item.label);
        const outSidingPaymentChecked = childChecked?.find((item) => item.label === 'outSidingPayment' && item.value);
        if ((selectedStatuses?.length ?? 0) > 0 || outSidingPaymentChecked) {
            addedUrl += ';reservation.status:';
            if (selectedStatuses && selectedStatuses.length > 0) {
                addedUrl += selectedStatuses.join(',');
            }
            if (outSidingPaymentChecked) {
                if (selectedStatuses && selectedStatuses.length > 0) {
                    addedUrl += ',';
                }
                addedUrl += 'pending,inProgress';
            }
        }
        const queryParams = {
            search: {
                'rentable.registration_number': filterName,
                rentableExtraId,
                'reservation.status': reservationStatus,
                rentableId,
                'reservation.customer.id': customerId,
                addedUrl,
            },
            page: Number(page) + 1,
            perPage: rowsPerPage,
            orderBy,
            sortedBy: order,
            isPaging,
        };
        const url = createDynamicURL('/reservationItems', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRejectedReservationItems = createAsyncThunk('reservations/getRejectedReservationItems', async () => {
    let data;
    try {
        const queryParams = {
            search: {
                rentableExtraId: null,
                'reservation.status': 'pending',
                rentableId: null,
            },
            isPaging: false,
        };
        const url = createDynamicURL('/reservationItems', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const UpdateReservationInCalendar = createAsyncThunk('reservations/UpdateReservationInCalendar', async ({ id, values }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/reservations/${id}`, values);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getReservationItems({ isPaging: false }));
            return { data, id, values };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getReservationItem = createAsyncThunk('reservations/getReservationItem', async (id) => {
    let data;
    try {
        const response = await axios.get(`/reservationItems/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getNoInvoiceReservations = createAsyncThunk('reservations/getNoInvoiceReservations', async ({ orderBy = 'createdAt', order = 'desc', customerId }) => {
    const url = '/reservations/withoutInvoice';
    const queryParams = {
        customerId,
        orderBy,
        sortedBy: order,
        isPaging: false,
    };
    try {
        const getUrl = createDynamicURL(url, queryParams);
        const response = await axios.get(getUrl);
        const data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getPickupByReservationId = createAsyncThunk('reservations/getPickupByReservationId', async (body) => {
    let data;
    try {
        const response = await axios.get(`/reservations/${body.id}/pickup`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const postPickupByReservationId = createAsyncThunk('reservations/postPickupByReservationId', async (body, thunkApi) => {
    let data;
    if (body.data?.firstConductor?.nationalityId)
        body.data.firstConductor.nationalityId = Number(body.data.firstConductor.nationalityId);
    if (body.data?.secondConductor?.nationalityId)
        body.data.secondConductor.nationalityId = Number(body.data.secondConductor.nationalityId);
    try {
        const response = await axios.post(`/reservations/${Number(body.id)}/pickup`, body.data);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getOneReservation(Number(body.id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createReservation = createAsyncThunk('reservations/createReservation', async (body) => {
    let data;
    try {
        const response = await axios.post(`/reservations`, {
            overload: body.overload,
            manualPrice: body.manualPrice,
            ...RemoveNullValues(body),
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const updateReservation = createAsyncThunk('reservations/updateReservation', async ({ values, id }, thunkApi) => {
    delete values.payments;
    let data;
    try {
        const response = await axios.put(`/reservations/${id}/edit`, {
            overload: values.overload,
            manualPrice: values.manualPrice,
            discount: values.discount,
            ...RemoveNullValues(values),
        });
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const reservationPaymentMethods = createAsyncThunk('reservations/reservationPaymentMethods', async () => {
    let data;
    try {
        const response = await axios.get(`/reservationPaymentMethods`);
        data = await response.data.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createReservationPayment = createAsyncThunk('reservations/createReservationPayment', async ({ body, id }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservationPayment`, body);
        data = await response.data;
        if (response.status === 200) {
            if (id)
                thunkAPI.dispatch(getReservationPayment(id));
            if (body.type === 'penalty')
                thunkAPI.dispatch(addToReservationTotalAmount(body.amount));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getReservationPayment = createAsyncThunk('reservations/getReservationPayment', async (id) => {
    let data;
    try {
        const response = await axios.get(`/reservationPayment/reservation/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getOneReservation = createAsyncThunk('reservations/getOneReservation', async (id, thunkAPI) => {
    let data;
    try {
        const response = await axios.get(`/reservations/${id}`);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(setReservationTotalAmount(data.data.totalAmountWithPenalties));
            return { data };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getCostTransfer = createAsyncThunk('reservations/pickupLocation/costTransfer', async (body) => {
    let data;
    try {
        const response = await axios.get(`/reservations/pickupLocation/${Number(body.idPickup)}/returnLocation/${Number(body.idReturn)}/costTransfer`);
        data = await response.data;
        if (response.status === 200) {
            return {
                data,
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getReservationContract = createAsyncThunk('reservations/getReservationContract', async (id) => {
    let data;
    try {
        const response = await axios.get(`/reservations/${id}/contract`, {
            responseType: 'arraybuffer',
        });
        data = await response.data;
        if (response.status === 200) {
            return new Uint8Array(data);
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getTodayReservations = createAsyncThunk('reservations/getTodayReservations', async ({ page, rowsPerPage, orderBy }) => {
    let data;
    const today = dayjs();
    const startDate = today.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = today.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    try {
        const queryParams = {
            search: {
                status: 'inProgress,pending',
                startDate: `${startDate},${endDate}`,
                endDate: `${startDate},${endDate}`,
            },
            searchJoin: 'OR',
            page: Number(page) + 1,
            perPage: rowsPerPage,
            orderBy,
            sortedBy: 'desc',
        };
        const url = createDynamicURL('/reservations', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getTodayReturns = createAsyncThunk('reservations/getTodayReturns', async ({ page, rowsPerPage, orderBy, order }) => {
    let data;
    const today = dayjs();
    const startDate = today.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = today.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    try {
        const queryParams = {
            search: {
                status: 'inProgress',
                endDate: `${startDate},${endDate}`,
            },
            page: Number(page) + 1,
            perPage: rowsPerPage,
            orderBy,
            sortedBy: order,
        };
        const url = createDynamicURL('/reservations', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getTodayPickups = createAsyncThunk('reservations/getTodayPickups', async ({ page, rowsPerPage, orderBy, order }) => {
    let data;
    const today = dayjs();
    const startDate = today.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = today.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    try {
        const queryParams = {
            search: {
                status: 'pending',
                startDate: `${startDate},${endDate}`,
            },
            page: Number(page) + 1,
            perPage: rowsPerPage,
            orderBy,
            sortedBy: order,
        };
        const url = createDynamicURL('/reservations', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const reportNonFunctionalDamage = createAsyncThunk('reservations/reportNonFunctionalDamage', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/report/nonFunctionalDamage`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const reportFunctionalDamage = createAsyncThunk('reservations/report/FunctionalDamage', async ({ id, ...Data }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/reservations/${id}/report/functional`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getOneReservation(Number(id)));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getDepositAmount = createAsyncThunk('reservations/depositAmount', async () => {
    let data;
    try {
        const response = await axios.get(`/reservations/depositAmount`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        setIsDescriptionRequired(state, action) {
            state.isDescriptionRequired = Boolean(Number(action.payload));
        },
        setReservationTotalAmount(state, action) {
            state.reservationTotalAmount = Number(Number.parseFloat(action.payload).toFixed(3));
        },
        addToReservationTotalAmount(state, action) {
            state.reservationTotalAmount = Number(Number.parseFloat(action.payload + state.reservationTotalAmount).toFixed(3));
        },
        setReservationPaymentDifference(state, action) {
            state.reservationPaymentDifference = Number(Number.parseFloat(action.payload).toFixed(3));
        },
        setReservationPaymentPaid(state, action) {
            state.reservationPaymentPaid = action.payload;
        },
        gotoStep(state, action) {
            const step = action.payload;
            state.activeStep = step;
        },
        backStep(state) {
            if (state && state.activeStep !== undefined) {
                state.activeStep -= 1;
            }
        },
        nextStep(state) {
            if (state && state.activeStep !== undefined) {
                state.activeStep += 1;
            }
        },
        resetCart(state) {
            state.activeStep = 0;
        },
        handleChangeParentCheckFilter(state, action) {
            state.parentChecked = action.payload;
        },
        handleChangeChildCheckFilter(state, action) {
            state.childChecked = action.payload;
        },
        handleResetReservationFilter(state, action) {
            state.parentChecked = true;
            state.childChecked = state.childChecked.map(({ label }) => ({
                value: false,
                label,
            }));
        },
        handleIsOpenFirstConductor(state, action) {
            state.isOpenFirstConductor = action.payload;
        },
        handleIsOpenSecondConductor(state, action) {
            state.isOpenSecondConductor = action.payload;
        },
        handleIsOpenConductor(state, action) {
            state.isOpenConductor = action.payload;
        },
        sendRentableTotalPrice(state, action) {
            state.checkout.totalPrice = action.payload;
            state.checkout.total =
                state.checkout?.manualPrice?.toString() === ''
                    ? add(action.payload, Number(state.costTransfer))
                    : Number(state.checkout.manualPrice);
        },
        sendFirstStepData(state, action) {
            state.stepOneData = action.payload;
        },
        getSummary(state, action) {
            const extra = action.payload;
            const stepOneData = state.stepOneData;
            const start = new Date(fFormatDate(stepOneData?.startDate));
            const end = new Date(fFormatDate(stepOneData?.endDate));
            const numberOfDays = differenceInDays(end, start);
            const totalExtraPrice = extra && extra.reduce((total, item) => total + item.price * item.qty, 0) * numberOfDays;
            state.checkout.extras = extra;
            state.checkout.totalExtraPrice = totalExtraPrice;
            if (extra?.length === 0) {
                state.checkout.total =
                    state?.checkout?.manualPrice?.toString() === ''
                        ? add(state.checkout.totalPrice, Number(state.costTransfer))
                        : Number(state.checkout.manualPrice);
            }
            else {
                state.checkout.total = calculateTotalCheckout(state.checkout.manualPrice, state.checkout.totalPrice, state.checkout.overload, totalExtraPrice, state.checkout.discount, Number(state.costTransfer));
            }
        },
        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            const totalPrice = state.checkout.totalPrice || 0;
            const totalExtraPrice = state.checkout.totalExtraPrice || 0;
            let parsedDiscount = Number(discount);
            if (Number.isNaN(parsedDiscount)) {
                parsedDiscount = 0;
            }
            const SubTotal = add(totalPrice, totalExtraPrice) - parsedDiscount;
            state.checkout.subTotal = SubTotal;
            state.checkout.total = calculateTotalCheckout(state.checkout.manualPrice, state.checkout.totalPrice, state.checkout.overload, totalExtraPrice, state.checkout.discount, Number(state.costTransfer));
        },
        applyOverLoad(state, action) {
            const overload = action.payload;
            let parsedOverload = Number(overload);
            if (Number.isNaN(parsedOverload)) {
                parsedOverload = 0;
            }
            const totalExtraPrice = state.checkout.totalExtraPrice;
            state.checkout.overload = parsedOverload;
            state.checkout.total = calculateTotalCheckout(state.checkout.manualPrice, state.checkout.totalPrice, state.checkout.overload, totalExtraPrice, state.checkout.discount, Number(state.costTransfer));
        },
        applyManualPrice(state, action) {
            const manualPrice = action.payload;
            state.checkout.manualPrice = manualPrice;
            const totalExtraPrice = state.checkout.totalExtraPrice;
            state.checkout.total = calculateTotalCheckout(state.checkout.manualPrice, state.checkout.totalPrice, state.checkout.overload, totalExtraPrice, state.checkout.discount, Number(state.costTransfer));
        },
        getCheckedOptions(state, action) {
            state.checkedOptions = action.payload;
        },
        emptyTotalExtraPrice(state, action) {
            state.checkout.extras = [];
            state.checkout.totalExtraPrice = 0;
            state.checkout.total = 0;
            state.checkout.discount = 0;
            state.checkout.totalPrice = 0;
            state.checkout.subTotal = 0;
            state.checkout.overload = 0;
            state.checkout.manualPrice = '';
            state.checkedOptions = [];
            state.selectedTypes = [];
        },
        handelSelectedTypes(state, action) {
            state.selectedTypes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNoInvoiceReservations.pending, (state) => {
            state.getReservationsStatus = 'loading';
        })
            .addCase(getNoInvoiceReservations.fulfilled, (state, action) => {
            state.getReservationsStatus = 'succeeded';
            state.invoiceReservation = action.payload.data;
        })
            .addCase(getNoInvoiceReservations.rejected, (state, action) => {
            state.getReservationsStatus = 'failed';
        })
            .addCase(getReservationItems.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getReservationItems.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.reservationItems = action.payload.data;
            state.calendarReservations = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getReservationItems.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getRejectedReservationItems.pending, (state) => {
            state.rejectedStatus = 'loading';
        })
            .addCase(getRejectedReservationItems.fulfilled, (state, action) => {
            state.rejectedStatus = 'succeeded';
            state.rejectedReservationItems = action.payload.data;
        })
            .addCase(getRejectedReservationItems.rejected, (state, action) => {
            state.rejectedStatus = 'failed';
        })
            .addCase(getReservationItem.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getReservationItem.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.reservationItem = action.payload;
        })
            .addCase(getReservationItem.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createReservation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createReservation.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createReservation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(reservationPaymentMethods.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(reservationPaymentMethods.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.listReservationPaymentMethods = action.payload;
        })
            .addCase(reservationPaymentMethods.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getPickupByReservationId.pending, (state) => {
            state.getPickupsStatus = 'loading';
        })
            .addCase(getPickupByReservationId.fulfilled, (state, action) => {
            state.getPickupsStatus = 'succeeded';
            state.reservationPickup = action.payload.data;
        })
            .addCase(getPickupByReservationId.rejected, (state, action) => {
            state.getPickupsStatus = 'failed';
        })
            .addCase(getReservationPayment.pending, (state) => {
            state.reservationPaymentStatus = 'loading';
        })
            .addCase(getReservationPayment.fulfilled, (state, action) => {
            state.reservationPaymentStatus = 'succeeded';
            state.reservationPayment = action.payload.data;
        })
            .addCase(getReservationPayment.rejected, (state, action) => {
            state.reservationPaymentStatus = 'failed';
        })
            .addCase(UpdateReservationInCalendar.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(UpdateReservationInCalendar.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.calendarReservations = state.calendarReservations.map((item) => item.reservationId === action.payload?.data?.data?.id
                ? {
                    ...item,
                    reservation: action.payload.data.data,
                }
                : { ...item });
        })
            .addCase(UpdateReservationInCalendar.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneReservation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneReservation.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.oneReservation = action.payload.data.data;
            state.reservationPayment = action.payload.data.data.reservationPayments;
        })
            .addCase(getOneReservation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getCostTransfer.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getCostTransfer.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.costTransfer = action.payload.data.data;
        })
            .addCase(getCostTransfer.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getReservationContract.pending, (state) => {
            state.pdfStatus = 'loading';
        })
            .addCase(getReservationContract.fulfilled, (state, action) => {
            state.pdfStatus = 'succeeded';
            state.pdf = action.payload;
        })
            .addCase(getReservationContract.rejected, (state, action) => {
            state.pdfStatus = 'failed';
        })
            .addCase(getTodayReservations.pending, (state) => {
            state.todayReservationsStatus = 'loading';
        })
            .addCase(getTodayReservations.fulfilled, (state, action) => {
            state.todayReservationsStatus = 'succeeded';
            state.todayReservations = action.payload.data;
            state.todayReservationsMeta = action.payload.meta;
        })
            .addCase(getTodayReservations.rejected, (state, action) => {
            state.todayReservationsStatus = 'failed';
        })
            .addCase(getTodayReturns.pending, (state) => {
            state.todayReturnsStatus = 'loading';
        })
            .addCase(getTodayReturns.fulfilled, (state, action) => {
            state.todayReturnsStatus = 'succeeded';
            state.todayReturns = action.payload.data;
            state.todayReturnsMeta = action.payload.meta;
        })
            .addCase(getTodayReturns.rejected, (state, action) => {
            state.todayReturnsStatus = 'failed';
        })
            .addCase(getTodayPickups.pending, (state) => {
            state.todayPickupsStatus = 'loading';
        })
            .addCase(getTodayPickups.fulfilled, (state, action) => {
            state.todayPickupsStatus = 'succeeded';
            state.todayPickups = action.payload.data;
            state.todayPickupsMeta = action.payload.meta;
        })
            .addCase(getTodayPickups.rejected, (state, action) => {
            state.todayPickupsStatus = 'failed';
        })
            .addCase(reportFunctionalDamage.pending, (state) => {
            state.pdfStatus = 'loading';
        })
            .addCase(reportFunctionalDamage.fulfilled, (state, action) => {
            state.pdfStatus = 'succeeded';
        })
            .addCase(reportFunctionalDamage.rejected, (state, action) => {
            state.pdfStatus = 'failed';
        })
            .addCase(reportNonFunctionalDamage.pending, (state) => {
            state.pdfStatus = 'loading';
        })
            .addCase(reportNonFunctionalDamage.fulfilled, (state, action) => {
            state.pdfStatus = 'succeeded';
        })
            .addCase(reportNonFunctionalDamage.rejected, (state, action) => {
            state.pdfStatus = 'failed';
        })
            .addCase(getDepositAmount.pending, (state) => {
            state.getDepositAmountStatus = 'loading';
        })
            .addCase(getDepositAmount.fulfilled, (state, action) => {
            state.getDepositAmountStatus = 'succeeded';
            state.depositAmount = action.payload.data;
        })
            .addCase(getDepositAmount.rejected, (state, action) => {
            state.getDepositAmountStatus = 'failed';
        })
            .addCase(createReservationPayment.rejected, (state, action) => {
            state.paymentStatus = 'failed';
        })
            .addCase(createReservationPayment.pending, (state) => {
            state.paymentStatus = 'loading';
        })
            .addCase(createReservationPayment.fulfilled, (state, action) => {
            state.paymentStatus = 'succeeded';
        });
    },
});
export const { backStep, nextStep, sendFirstStepData, gotoStep, resetCart, handleChangeParentCheckFilter, handleChangeChildCheckFilter, handleResetReservationFilter, handleIsOpenFirstConductor, handleIsOpenSecondConductor, getSummary, sendRentableTotalPrice, applyDiscount, applyOverLoad, applyManualPrice, getCheckedOptions, handelSelectedTypes, handleIsOpenConductor, emptyTotalExtraPrice, setReservationPaymentDifference, setReservationPaymentPaid, setReservationTotalAmount, addToReservationTotalAmount, setIsDescriptionRequired, } = slice.actions;
export const reducer = slice.reducer;
export default slice;
